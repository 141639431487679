import { createStore, combineReducers, applyMiddleware } from 'redux';
import reduxThunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import locales from "./redux/reducers/locales";
import textback from "./redux/reducers/textback";

import middleware from "./redux/middlewares";

const store = createStore(
  combineReducers({
    locales,
    textback,
  }),
  composeWithDevTools(
    applyMiddleware(
      reduxThunk,
      middleware,
    )),
);

store.subscribe(() => {

});

export default store;
