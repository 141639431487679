import React from 'react';
import { NavLink } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import classnames from 'classnames/index';
import { useMobile } from '../../../../hooks';
import Icons from '../Icons';

import {
  REACT_APP_CONTACT_EMAIL,
  //REACT_APP_SITENAME
} from './../../../../config';

import Holder from '../Holder'
import './footer.css';

export const Footer = ({type}) => {
  const isMobile = useMobile();

  const FooterNavList = [
    { name: 'About Us', link: '/about-us' },
    { name: 'Join Network', link: '/join-network' },
    { name: 'Honor Code', link: '/honor-code' },
    { name: 'Report Abuse', link: '/report-abuse' },

    { name: 'Privacy Policy', link: '/privacy-policy' },
    { name: 'Refund Policy', link: '/refund-policy' },
    { name: 'Cookie Policy', link: '/cookie-policy' },
    { name: 'Terms of Service', link: '/terms-of-service' },
  ];

  const FooterNav = () => {
    return (
      <nav className='footer-nav-list'>
        {FooterNavList.map((item, key) => {
          return(
            <div className='footer-nav-item' key={`footer_item_${key}`}>
              <NavLink className='footer-nav-link' to={item.link}>{item.name}</NavLink>
            </div>
          )
        })}
      </nav>
    )
  };

  return (
    <footer className={classnames('footer', {[`footer_${type}`]:type})}>
      <Holder type={1} className={'footer-holder'}>
        <div className="footer-column">
          <HashLink smooth to="/#hero">
            <img className="footer-logo-icon" src={require('./img/logo-light.svg')} alt='Quiz Easy — urgent help from experts with any online task'/>
          </HashLink>

          {!!isMobile && <FooterNav />}

          <div className="footer-label">Contact us:</div>
          <a className="footer-contact-link" href={"mailto:" + REACT_APP_CONTACT_EMAIL}>
            <Icons className="footer-mail-icon" iconName={`ic-mail`} />
            <span className="footer-contact-email">{REACT_APP_CONTACT_EMAIL}</span>
          </a>
          <div className="footer-copyright">&copy; 2022 quiz-easy.com. All rights reserved.</div>
        </div>

        {!isMobile && <FooterNav />}
      </Holder>
    </footer>
  )
};

