import React, { Component, useState, useEffect, useCallback } from 'react';
import { initLocale, setLocale, showModal } from "../../redux/actions";
import { connect, useSelector, useDispatch } from "react-redux";
import {
  get
} from 'lodash'

import { localesData } from "./../../utils/locales";

export const LocalesHOC = (WrappedComponent) => {
  class LocalesHOC extends Component {
    constructor(props) {
      super(props);
    }

    componentDidMount() {
      const { pageCfg, locale } = this.props;
      const { defaultLocale, defaultPhone, landingGuid } = pageCfg;

      this.props.initLocale({
        locale: defaultLocale || locale,
        defaultPhone,
        landingGuid
      });
    }

    render() {
      return <WrappedComponent
        {...this.props}
      />
    }
  }

  LocalesHOC.displayName = `LocalesLandingHOC(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`;

  return connect((state, ownProps) => {
    const locales = state.locales;
    const { locale } = locales;

    return {
      locale,
    };

  }, (dispatch, ownProps) => {
    return {
      initLocale: (data) => dispatch(initLocale(data)),
    }
  })(LocalesHOC);
};

export const LocalesSwitcherHOC = (WrappedComponent) => {
  class LocalesSwitcherHOC extends Component {
    constructor(props) {
      super(props);

      this.onChange = this.onChange.bind(this);
    }

    onChange(e) {
      const { value } = e.target;

      const code = Object.keys(localesData)
        .find(item => localesData[item].country === value);

      this.props.setLocale({
        locale: code
      });
    }

    render() {
      const { locale, isLocaleSet } = this.props;

      const items = Object.keys(localesData).map(item => {
        return {
          value: localesData[item].country,
          countryCode: item,
        }
      });

      if (!isLocaleSet) {
        return null;
      }

      return <WrappedComponent
        countryCode={locale}
        value={get(localesData, `${locale}.country`)}
        onChange={this.onChange}
        items={items}
        {...this.props}
      />
    }
  }

  LocalesSwitcherHOC.displayName = `LocalesSwitcherHOC(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`;

  return connect((state, ownProps) => {
    const locales = state.locales;
    const { locale, isLocaleSet } = locales;

    return {
      locale,
      isLocaleSet,
    };

  }, (dispatch, ownProps) => {
    return {
      setLocale: (locale) => dispatch(setLocale(locale)),
    }
  })(LocalesSwitcherHOC);
};

export const useLocalesModal = () => {
  const locales = useSelector(state => state.locales);
  const { isModalOpen, userLocale } = locales;

  const [country, setCountry] = useState('');
  const [countryCode, setCountryCode] = useState('');

  const dispatch = useDispatch();

  useEffect(() => {
    if (userLocale) {
      setCountry(localesData[userLocale].country);
      setCountryCode(userLocale);
    }
  }, [userLocale]);

  const onChange = (e) => {
    const { value } = e.target;

    const code = Object.keys(localesData)
      .find(item => localesData[item].country === value);

    setCountryCode(code);
  }

  const onSave = useCallback(
    () => {
      dispatch(setLocale({
        locale: countryCode,
      }))
      dispatch(showModal(false))
    },
    [dispatch, countryCode]
  );

  const onClose = useCallback(
    () => {
      dispatch(showModal(false))
    },
    [dispatch]
  );

  const items = Object.keys(localesData).map(item => {
    return {
      value: localesData[item].country,
      countryCode: item,
    }
  });

  return {
    onChange,
    onClose,
    onSave,
    isOpen: isModalOpen,
    countryCode,
    country,
    items: items,
  }
}


