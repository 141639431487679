import React, { Component } from 'react';
import Slider from 'react-slick';

import './index.css';

export default class ResponsiveSlider extends Component {
  render() {
    let settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1058,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
      ]
    };
    let testimonialList = [
      {
        img: "Denis-Robinson",
        name: "Denis Robinson",
        educate: "University of Kansas",
        message: "My first assigned expert was Chris and I can honestly say that this match was made in heaven! I still ask to get him to help me with any Math quiz cuz he's an awesome tutor. I was able to achieve good results on my course and the price was worth the peace of mind. You’re the best, quiz easy!"
      },
      {
        img: "Caroline-Walker",
        name: "Caroline Walker",
        educate: "University of South Florida",
        message: "I still can't say that I became a fan of online classes, but Quiz Easy website makes it a lot easier for sure. You are the best! I am so glad that at some point I decided to give it a try. Overall, I sent a total of 6 requests in various subjects (mainly humanities) and I was very pleased with the results each time."
      },
      {
        img: "Shaun-Hall",
        name: "Shaun Hall",
        educate: "Boston College",
        message: "The biggest thank you to quiz easy. Impeccable service and swift replies even at 5 a.m. The price is also fair considering the high quality of the assistance I got here. Shout out to Diana who worked with me so that I pass my task! They are 100% reliable, I am definitely coming back soon!"
      },
      {
        img: "Cassidy-Perkins",
        name: "Cassidy Perkins",
        educate: "University of Virginia",
        message: "My experience was really good. What I like about this service is that I can ask for help by sending texts for FREE as they don’t charge extra for that unlike other services. Daniel, who was matched with me on quiz easy, was very knowledgeable in the subject and helped me a lot!",
      },
      {
        img: "Joshua-Reed",
        name: "Joshua Reed",
        educate: "Georgetown University",
        message: "I've been using Quiz Easy since the pandemic as at some point  I realized I was failing behind one of my courses. Thanks to all the help I received here I've passed numerous tests with flying colors. I can finally breathe and sleep knowing I can reach out to them at any time. They actually help.",
      },
      {
        img: "Amar-Patel",
        name: "Amar Patel",
        educate: "The Ohio State University",
        message: "I hate that I still can’t attend all my classes on campus. Online lectures require so much focus and energy, I got distracted and anxious easily and even thought about leaving college. My friend recommended Quiz Easy and I decided to give it a try. Now this is my saving grace whenever I feel stressed.",
      },
    ];
    return (
      <div className="testimonials-list container">
        <Slider {...settings}>
          {testimonialList.map((item, key) => {
            return(
              <div className="testimonials-item" key={`testimonials-item_${key}`}>
                <div className="testimonial-inner">
                <div className="testimonial-top">
                  <img
                    className="testimonial-img"
                    src={require(`./img/photo/${item.img}.png`)}
                    srcSet={require(`./img/photo/${item.img}@2x.png`) + ' 2x'}
                    alt="Quiz Easy — urgent help from experts with any online task"/>
                  <div className="testimonial-top-column">
                    <div className="testimonial-name">{item.name}</div>
                    <div className="testimonial-educate">{item.educate}</div>
                  </div>
                </div>
                <div className="testimonial-message">{item.message}</div>
                </div>
              </div>
            )
          })}
        </Slider>
      </div>
    )
  }
}
