import React, { useEffect } from 'react';
import LocalesPage from '../LocalesPage';

export default (props) => {
  const { pageCfg } = props;
  const redirectLocation = pageCfg?.redirectLocation;

  useEffect(() => {
    if (redirectLocation) {
      window.location.assign(redirectLocation);
    }
  });

  return  (
    <LocalesPage {...props}>
    </LocalesPage>
  )
}
