import React, { useState } from 'react';
import ResponsiveSlider from '../../blocks/Slider'
import { NavLink } from 'react-router-dom';

import Holder from '../../blocks/Holder';
import { Header } from '../../blocks/Header';
import { Footer } from '../../blocks/Footer';
import { TextBack } from '../../blocks/TextBack';
//import { JoinModal } from '../../blocks/Modal/JoinModal';
import { ThankYouModal } from '../../blocks/Modal/ThankYouModal';

import LocalesPage from '../LocalesPage';

//import Icon from '../../../../themes/nerdify/components/Icons';
import { useMobile } from '../../../../hooks';
//import LocalesPage from '../LocalesPage';

import './landing.css';
//import classnames from 'classnames/index';

export default (props) => {
  const isMobile = useMobile();
  const { pageCfg } = props;
/*
  const [isJoinModalOpen, setJoinModalOpen] = useState(false);

  function onAppModalClose() {
    setJoinModalOpen(false)
  }
*/

  return (
    <LocalesPage {...props}>
      <Header />

      <main className="main">

        <section className="landing-hero" id="hero">
          <div className="landing-hero-bg">
            <Holder type={1} className={'landing-hero-holder'}>
              <div className="landing-hero-column">
                <h1 className="landing-title">We make any online task/quiz EASY for you</h1>
                <div className="landing-subtitle">
                  Request urgent help from our experts
                  <img
                    className="landing-hero-icon"
                    src={require('./img/smile-wink-icon.png')}
                    srcSet={require('./img/smile-wink-icon@2x.png') + ' 2x'}
                    alt="Quiz Easy — urgent help from experts with any online task"/>
                </div>

                <div className="textback">
                  <div className="textback__wrapper">
                    <TextBack {...props} />
                    <div className="agree">
                        Clicking the button, you agree to our <NavLink className="link" to="/terms-of-service">terms</NavLink> and <NavLink className="link" to="/privacy-policy">privacy policy</NavLink>.<br/>
                      FYI, we respect your privacy and hate spam!</div>
                  </div>
                </div>

              </div>
              <img
                className="landing-hero-img"
                src={require('./img/hero/hero-image.png')}
                srcSet={require('./img/hero/hero-image@2x.png') + ' 2x'}
                alt="Quiz Easy — urgent help from experts with any online task"/>
            </Holder>
          </div>
        </section>

        <section className="points-section" id="how">
          <Holder type={1} className={'points-holder'}>
            <h2 className="title points-title">Quiz? Easy!<br className="mobile"/> How it works:</h2>
            <div className="points-list">
              <div className="points-item">
                <img className="points-img" src={require('./img/1-img.svg')} alt="Quiz Easy — urgent help from experts with any online task"/>
                <div className="points-message">Text us the task you have, or send a photo of it</div>
              </div>
              <div className="points-item">
                <img className="points-img" src={require('./img/2-img.svg')} alt="Quiz Easy — urgent help from experts with any online task"/>
                <div className="points-message">Send the prepayment (we do not store your CC info)</div>
              </div>
              <div className="points-item">
                <img className="points-img" src={require('./img/3-img.svg')} alt="Quiz Easy — urgent help from experts with any online task"/>
                <div className="points-message">Work on a solution with the matched expert</div>
              </div>
            </div>
          </Holder>
        </section>

        <section className="dashboard-section">
            <Holder type={1} className={'dashboard-holder'}>
              <div className="dashboard-title">Our experts help with tasks from any dashboard:</div>
               <img
                    className="dashboard-icons mobile"
                    src={require('./img/dashboards/logos-mob.png')}
                    srcSet={require('./img/dashboards/logos-mob@2x.png') + ' 2x, ' + require('./img/dashboards/logos-mob@3x.png') + ' 3x'}
                    alt="Quiz Easy — urgent help from experts with any online task"/>
                <img
                    className="dashboard-icons desktop"
                    src={require('./img/dashboards/logos-desk.png')}
                    srcSet={require('./img/dashboards/logos-desk@2x.png') + ' 2x, ' + require('./img/dashboards/logos-desk@3x.png') + ' 3x'}
                    alt="Quiz Easy — urgent help from experts with any online task"/>
            </Holder>
        </section>

        <section className="about-section" id="about">
          <Holder type={1} className={'about-holder'}>
            {!isMobile &&
            <img
              className="about-img"
              src={require('./img/about-image.png')}
              srcSet={require('./img/about-image@2x.png') + ' 2x'}
              alt="Quiz Easy — urgent help from experts with any online task"/>
            }
            <div className="about-column">
              <h2 className="title about-title">About Us</h2>
              <p className="about-message">Quiz Easy is a network of freelance educators with a focus on assistance with online tasks. We aimed to create a service making any difficult test/quiz easy to understand. Operating since the start of the pandemic Quiz Easy matches students with experts to get personalized help and prepare for all types of dashboard tasks. You will finally feel comfortable in the era of remote education if you choose us!</p>
              <NavLink className="about-link" to="/about-us">Learn more →</NavLink>

            </div>
            {!!isMobile &&
            <img
              className="about-img"
              src={require('./img/about-image-mob.png')}
              srcSet={require('./img/about-image-mob@2x.png') + ' 2x'}
              alt="Quiz Easy — urgent help from experts with any online task"/>
            }
          </Holder>
        </section>

        <section className="why-section" id="why">
          <Holder type={1} className={'why-holder'}>
            <h2 className="title why-title">Why Choose Us</h2>
            <div className="why-list">

              <div className="why-item">
                <img className="why-img" src={require('./img/why/why1.svg')} alt="Quiz Easy — urgent help from experts with any online task"/>
                <div className="why-column">
                  <div className="why-item-title">We cover your needs</div>
                  <div className="why-item-message">From Blackboard to Canvas and any other dashboard — we'll help you.</div>
                </div>
              </div>

              <div className="why-item">
                  <img className="why-img" src={require('./img/why/why2.svg')} alt="Quiz Easy — urgent help from experts with any online task"/>
                  <div className="why-column">
                      <div className="why-item-title">We stand for quality</div>
                      <div className="why-item-message">100% money-back guarantee<br className="mobile"/> if you don't like the service.</div>
                  </div>
              </div>

              <div className="why-item">
                <img className="why-img" src={require('./img/why/why3.svg')} alt="Quiz Easy — urgent help from experts with any online task"/>
                <div className="why-column">
                  <div className="why-item-title">We are reliable</div>
                  <div className="why-item-message">Your privacy is our #1 priority,<br className="mobile"/> we never share personal info.</div>
                </div>
              </div>

              <div className="why-item">
                <img className="why-img" src={require('./img/why/why4.svg')} alt="Quiz Easy — urgent help from experts with any online task"/>
                <div className="why-column">
                  <div className="why-item-title">We are born ready</div>
                  <div className="why-item-message">Need help in the middle of the night?<br className="mobile"/> We work 24/7!</div>
                </div>
              </div>

            </div>
          </Holder>
        </section>


        <section className="landing-hero3">
          <Holder type={1} className={'hero3-holder'}>
            <div className="hero3-block">
              <div className="hero3-title">Give us a chance to help you this&nbsp;time!</div>
              <div className="hero3-subtitle">
                We'll text you in less than 1 min
                <img
                  className="landing-hero-icon"
                  src={require('./img/smile-wink-icon.png')}
                  srcSet={require('./img/smile-wink-icon@2x.png') + ' 2x'}
                  alt="Quiz Easy — urgent help from experts with any online task"/>
              </div>

              <div className="textback">
                <div className="textback__wrapper">
                  <TextBack {...props} />
                  <div className="agree">Clicking the button, you agree to our <NavLink className="link" to="/terms-of-service">terms</NavLink> and <NavLink className="link" to="/privacy-policy">privacy policy</NavLink>.<br/>
                    FYI, we respect your privacy and hate spam!
                  </div>
                </div>
              </div>

              <div className="hero3-logo-block">
                <img className="logo-satisfaction" src={require('./img/hero/logo-satisfaction.svg')} alt="Quiz Easy — urgent help from experts with any online task"/>
                <img className="logo-guarantee" src={require('./img/hero/logo-guarantee.svg')} alt="Quiz Easy — urgent help from experts with any online task"/>
              </div>

            </div>

            <img className="hero3-img" src={require('./img/hero/ill-hero3.svg')} alt="Quiz Easy — urgent help from experts with any online task"/>

          </Holder>
        </section>


        <section className="testimonials-section">
          <Holder className={'testimonials-holder'}>
            <h2 className="title testimonials-title">Check out what students say
              <img
                className="testimonial-heart-icon"
                src={require('./img/ico-smile-heart.png')}
                srcSet={require('./img/ico-smile-heart@2x.png') + ' 2x, ' + require('./img/ico-smile-heart@3x.png')  + ' 3x'}
                alt="Quiz Easy — urgent help from experts with any online task"/>
            </h2>
            <ResponsiveSlider />
          </Holder>
        </section>

        <section className="savior-section">
          <Holder type={1} className={'savior-holder'}>
            <h2 className="title savior-title">How do we match experts?</h2>
            <div className="savior-subtitle">We select the expert among freelancers after checking your task requirements. There are more than a thousand of top-level educators in our network with at least 3+ years of online teaching/tutoring experience. We’ll find a match for any task/quiz. Easy-peasy!</div>
            <div className="savior-list">
              <div className="savior-item">
                <img
                  className="savior-img"
                  src={require('./img/savior/img1.png')}
                  srcSet={require('./img/savior/img1@2x.png') + ' 2x'}
                  alt="Quiz Easy — urgent help from experts with any online task"/>
                <div className="savior-message">80+ school/college<br/> subjects</div>
              </div>
              <div className="savior-item">
                <img
                  className="savior-img"
                  src={require('./img/savior/img2.png')}
                  srcSet={require('./img/savior/img2@2x.png') + ' 2x'}
                  alt="Quiz Easy — urgent help from experts with any online task"/>
                <div className="savior-message">1000+ experienced<br/> freelancers</div>
              </div>
              <div className="savior-item">
                <img
                  className="savior-img"
                  src={require('./img/savior/img3.png')}
                  srcSet={require('./img/savior/img3@2x.png') + ' 2x'}
                  alt="Quiz Easy — urgent help from experts with any online task"/>
                <div className="savior-message">7100+ tasks<br/> saved</div>
              </div>
            </div>
          </Holder>
        </section>

        <section className="landing-hero2">
          <Holder type={1} className={'hero2-holder'}>
            <h2 className="title hero2-title">Ready to try or still have questions?<br /> Let’s chat!</h2>
            <div className="hero2-subtitle">
              It usually takes seconds to contact you
              <img
                className="landing-hero-icon"
                src={require('./img/smile-wink-icon.png')}
                srcSet={require('./img/smile-wink-icon@2x.png') + ' 2x'}
                alt="Quiz Easy — urgent help from experts with any online task"/>
            </div>

            <div className="textback">
              <div className="textback__wrapper">
                <TextBack {...props} />
                <div className="agree">Clicking the button, you agree to our <NavLink className="link" to="/terms-of-service">terms</NavLink> and <NavLink className="link" to="/privacy-policy">privacy policy</NavLink>.</div>
              </div>
            </div>
            <hr className="hr"/>
            <div className="hero2-message">Quiz Easy network is proud to support academic integrity and does not tolerate its violations.</div>
          </Holder>
        </section>

        <section className="join-section">
          <Holder type={1} className={'join-holder'}>
            <h2 className="title join-title">Do you wish to join our network?</h2>
            <div className="join-subtitle">Fill out a form and we'll review your application.</div>
            <NavLink
              className="button button-tertiary submit-app"
              to={"/join-network"}
              //onClick={() => setJoinModalOpen(true)}
            >Apply</NavLink>
            <div className="join-message">It might take up to 7 days to contact you back,<br className="mobile"/> please be patient. </div>
          </Holder>
        </section>


{/*
        {!!isJoinModalOpen &&
        <JoinModal onClose={onAppModalClose}/>
        }
*/}

        {pageCfg.thankYouModal &&
        <ThankYouModal />
        }
      </main>

      <Footer />

    </LocalesPage>
  )
}
