import React from 'react';

import {
  //TextBack as TextBackBase,
  TextBackForm as TextBackFormBase,
} from '../../../../base_components/TextBack';
import { InputSelectLocales } from './InputSelect';

import './textback.css';

export const TextBack = (props) => (
  <TextBackFormBase
    {...props}
    renderSelectLocales={() => (
        <InputSelectLocales className="input-select__short" name="country" />
    )}
  >
  </TextBackFormBase>
);
