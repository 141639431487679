import React from 'react';
import {NavLink} from 'react-router-dom'
import ContentPage from './../ContentPage';
//import LocalesPage from './../LocalesPage';

export default () => (
    <ContentPage>
      <h1 className="content-title">QUIZ EASY HONOR CODE</h1>
      <p className="content-p">This Honor Code reflects the values of our company and states our expectations from both Students and Freelancers who access the website. Please note that if you do not agree with this Honor Code you are not allowed to use the website service for any purpose. </p>
      <p className="content-p">We do not tolerate academic fraud in any form, be it outsourcing assignments or not knowingly helping students to cheat and obtain grades/degrees not earned by themselves.</p>

      <p className="content-p content-p-subtitle">Students are not allowed to:</p>
      <ul className="content-ul">
        <li>Present the work or any educational materials of a freelancer as their own;</li>
        <li>Request a full assignment completion for further grading;</li>
        <li>Ask for unauthorized help with academic assignments.</li>
      </ul>

      <p className="content-p">Students who are caught infringing the restrictions specified in this Honor Code will have their account deactivated permanently with no option for recovery.</p>

      <p className="content-p content-p-subtitle">Freelancers are not allowed to:</p>
      <ul className="content-ul">
        <li>Provide the work or any educational materials to be presented as one’s own;</li>
        <li>Provide unauthorized help with academic assignments;</li>
        <li>Impersonate the client’s identity for any purpose;</li>
        <li>Contribute to circumstances that violate any college policy.</li>
      </ul>

      <p className="content-p">Freelancers who are caught being involved in cheating, plagiarizing, or engaged in any kind of illegal activity or action that violates any academic policy will be immediately banned.</p>

      <p className="content-p content-p-subtitle">The list of restricted requests include (please note that the list is not exhaustive):</p>
      <ul className="content-ul">
        <li>Completing college/school homework assignments;</li>
        <li>Assuming a User’s identity for any purpose;</li>
        <li>Preparing edu materials to be presented as their own work;</li>
        <li>Getting answers for tests, exams, labs when instructed not to use outside help;</li>
        <li>Forging documents of any type;</li>
        <li>Ghostwriting of essays, theses and term papers;</li>
        <li>Falsifying data, information, or citations;</li>
      </ul>
      <p className="content-p mt12">and other tasks that violate any of college policies.</p>

      <p className="content-p">If you believe that you witnessed a case of Honor Code infringement, please <NavLink to="/report-abuse" className="content-link">Report Abuse</NavLink>.</p>
  </ContentPage>
)
