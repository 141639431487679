import React, { useEffect, useState } from 'react';
import ContentPage from './../ContentPage';
import {
  REACT_APP_CONTACT_EMAIL,
} from './../../../../config';

import useForm from "../../hooks/useForm";


import "./report-abuse.css";

let radioList = [
  "Academic fraud and cheating",
  "Plagiarism or impersonating someone’s identity",
  "Any activity violating state laws",
  "Promotion of hatred or violence",
  "Sharing of sexually explicit materials",
  "Disclosure of private information",
  "Other"
];

export default function ReportAbuse() {
  const [caseVal, setCaseVal] = useState(radioList[0]);
//  const [mailtoUrl, setMailtoUrl] = useState('');

  const handleChangeRadio = (e) => {
    setCaseVal(e.target.value);
  };

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
  } = useForm(submit, validate);

  useEffect(() => {
  });

  function submit() {
    window.location = encodeURI(`mailto:${REACT_APP_CONTACT_EMAIL}?subject=Abuse Report&body=Case type: ${caseVal}
my email: ${values.email}
task number: ${values.task_number ? values.task_number : ''}
Additional information: ${values.info ? values.info : ''}`);
  }

  function validate(values) {

    let errors = {};
    if (!values.task_number) {
      //errors.task_number = 'This field is mandatory!';
    }
    if (!values.email) {
      errors.email = 'This field is mandatory!';
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
      errors.email = 'You have entered an invalid email address.';
    }

    if (!values.info) {
      //errors.info= 'This field is mandatory!'
    }

    return errors;
  }


  return (
    <ContentPage className={'report-holder'}>
      <h1 className="content-title">REPORT ABUSE</h1>
      <p className="report-msg">We’ve built an ethical business with high standards of service. If you are familiar with
        the case when a Freelancer agreed to provide unethical services or behaved inappropriately, please report it!
        It’s 100% anonymous and they will never know.</p>

      <div className="report-form-block">

        <div className="report-form-title">Please select the type that best describes the case you want to report:</div>

        <form> {/*action={mailtoUrl} method="GET"*/}
          <div className="radio-list">
          {radioList.map((item, key) => {
            return (
              <div className="radio-list-item" key={`radio-list-item_${key}`}>
                <input
                  className="radio-input"
                  type="radio"
                  id={`item_${key}`}
                  name="case"
                  value={item}
                  checked={ caseVal === item }
                  onChange={handleChangeRadio}
                />
                <label className="radio-label" htmlFor={`item_${key}`}>
                  <img className="radio-icon radio-icon-default" src={require(`./img/ico-radio-default.svg`)} alt="Quiz Easy — urgent help from experts with any online task"/>
                  <img className="radio-icon radio-icon-checked" src={require(`./img/ico-radio-checked.svg`)} alt="Quiz Easy — urgent help from experts with any online task"/>
                  <span className="radio-label-msg">{item}</span>
                </label>
              </div>
            )
          })}
        </div>

        <div className="form-line">

          <div className="form-item">
            <label htmlFor="email" className="form-label">Your email address</label>
            <input
              id="email"
              type="email"
              className={`form-input ${errors.email && 'error'}`}
              placeholder="example@gmail.com"
              value={values.email || ''}
              onChange={handleChange}
              //required
            />
            {errors.email && (
              <div className="form-item-error">{errors.email}</div>
            )}
          </div>

          <div className="form-item">
            <label htmlFor="task_number" className="form-label">Request number linked to the case</label>
            <input
              id="task_number"
              type="text"
              className={`form-input ${errors.task_number && 'error'}`}
              placeholder="Type here"
              value={values.task_number || ''}
              onChange={handleChange}
              //required
            />
            {errors.task_number && (
              <div className="form-item-error">{errors.task_number}</div>
            )}
          </div>

        </div>

        <div className="form-line">
          <div className="form-item">
            <label htmlFor="task_number" className="form-label">Additional information</label>
            <textarea
              name=""
              id="info"
              className="form-textarea"
              placeholder="Please share any details you want us to know about."
              value={values.info || ''}
              onChange={handleChange}
            />
            {errors.info && (
              <div className="form-item-error">{errors.info}</div>
            )}
          </div>
        </div>

          <button
            className="button report-submit-button"
            type="button"
            onClick={handleSubmit}>
            Submit
          </button>

        </form>
      </div>

    </ContentPage>
  )
}
