import React from 'react';
import ContentPage from './../ContentPage';
import {
  REACT_APP_CONTACT_EMAIL,
} from './../../../../config';

export default () => (
    <ContentPage>
      <h1 className="content-title">QUIZ EASY REFUND POLICY</h1>
      <p className="content-p">The refund option will be available for your payment only during the period of 60 (sixty) days from the date when your payment was made. To the fullest extent permitted by law, any refunds at any time are at our sole discretion only. No refund will be issued in case the User has lost connection due to the absence of Internet access, factory reset or updates. You may request to delete your Account at any time. In case Quiz Easy shall suspend or terminate your Account because of breach of any of our policies, you understand and agree that you shall receive no refund or exchange for any unused lessons or any portion of the Service, any content or data associated with your account, or for anything else. We use third party payment services to bill you through an online payments account in lieu of directly processing your credit/debit card information. We do not store your payment information on our servers.</p>
      <h2 className="content-h2">Refund of your subscription plan</h2>
      <p className="content-p">You can request a refund for the subscription fee charged to your card up to 30 days from the date of the latest payment. The refund can be issued only if you did not use your subscription after the renewal date (have no paid task). In this case, your refund request will be processed within 24 hours and you'll receive money back on your card within 3-5 business days.</p>
      <p className="content-p">You are required to send your refund request at <a href={`mailto:${REACT_APP_CONTACT_EMAIL}`} className="content-link">{REACT_APP_CONTACT_EMAIL}</a>. The refund decision shall be made up to 5 calendar days.</p>
    </ContentPage>
)
