import React from 'react';
//import {NavLink} from 'react-router-dom'
import ContentPage from './../ContentPage';
//import LocalesPage from './../LocalesPage';

import { REACT_APP_CONTACT_EMAIL } from './../../../../config';

export default () => (
    <ContentPage>
      <h1 className="content-title">QUIZ EASY PRIVACY POLICY</h1>
      <p className="content-p">Quiz Easy is committed to protecting your personal data. Please read carefully this Privacy Policy, so we can be sure that you completely understand the conditions under which we would cooperate. This Privacy Policy applies to your use of the Services of Quiz Easy, as defined in our Terms of Service. Quiz Easy offers you options concerning data we process, use and share as described in this Privacy Policy and Cookies Policy. This Privacy Policy explains when, where, and why we process personal data about people who use Quiz Easy, how we use it, the conditions under which we may disclose it to others, your rights in respect of your personal data, as well as how we keep it secure. </p>

      <h2 className="content-h2">1. General</h2>
      <p className="content-p">Anyone can access our Website without the necessity to register an account. Therefore, limited use of the Services is always possible without the provision of your personal details (for example, browse the Website, check how Quiz Easy works, etc.). However, whenever you access the Website, we collect log information and put cookies on your device, by which we track your activity on  the Website. You can always opt out of cookies except those, without which the Website cannot work.</p>
      <p className="content-p">Specifically, if you are a Freelancer Quiz Easy may collect from you and process Cookie identifiers and those data you specifically provide us with upon registration, including first name, last name, email, phone number, Skype ID, time zone, interface language, IP address, and device type. We may also collect and process, besides the aforementioned information, the other data provided by you, including zip-code, gender, year of birth, city of residence, and education experience.</p>
      <p className="content-p">We may change this Privacy Policy from time to time, so please check this page to ensure that you’re happy with any changes. We will post those changes on this page and update the Privacy Policy modification date at the top of this page. The additional notifications on any amendments will be sent to your email.</p>


    <h2 className="content-h2">2. Quiz Easy’s Lawful Basis for Processing</h2>
    <p className="content-p">Quiz Easy processes your personal data only when we have lawful bases. Specifically, Quiz Easy processes your personal data if:</p>
    <ul className="content-ul">
      <li>processing is based on the consent you have given. As such user feedback and reviews, placement of different types of cookies; </li>
      <li>processing is necessary to provide our services to you according to our Terms of Service which constitute a contract between you and us as regards to information services of QiuzEasy;</li>
      <li>processing is necessary for the purposes of our legitimate interests. Those legitimate interests are:<br/>
        - compliance with the U.S. financial and accounting legislation;<br/>
        - the assessment of Quiz Easy’s business efficiency by analyzing website traffic and financial performance indicators;<br/>
        - user session control and fraud prevention, ensuring the appropriate protection of personal data;</li>
      <li>where necessary, compliance with applicable legislation (e.g., data subject request processing);</li>
      <li>in exceptional cases, to protect your vital interests or vital interests of another natural person; or for the performance of a task carried out in the public interest or in the exercise of official authority vested in us.</li>
    </ul>
      <p className="content-p">In all cases, we will notify you about the applied legal basis for processing and other relevant details, before we start the processing of your personal data on a specific legal basis unless we are not able to do so in accordance with our obligations under any applicable legislation.</p>



      <h2 className="content-h2">3. Data We Collect: Scope</h2>
      <p className="content-p">Quiz Easy collects from you only the following information and only for the stated below purposes:</p>
      <p className="content-p">
        <span className="content-p-subtitle">(1) Account for Students</span>
        Quiz Easy collects and processes personal data submitted by you when contacting us and placing a request. You may share additional information at your sole discretion. Additional information may help you to get more from the Services, for instance, finding a necessary Freelancer. At the same time, you are not required to provide additional information, not specifically necessary for the Services, it is your choice.</p>
      <p className="content-p">
        <span className="content-p-subtitle">(2) Account for Freelancers</span>
        Quiz Easy collects and processes personal data submitted by you when applying and setting up your Profile. You submit your login credentials (username and passwords), first name, last name and email. You may edit your Profile with additional information at your sole discretion. Additional information may help you to get more from the Services.</p>
      <p className="content-p">
        <span className="content-p-subtitle">(3) Posting of Information</span>
        We process personal data freely submitted to you on Quiz Easy. This may be a survey you fill in on Quiz Easy or feedback you provide us with. Quiz Easy may also process information provided by you (including content and messages) that you post on/through our Website, including personally identifiable information. From our turn and unless you opt-out, we retain this information as necessary to resolve disputes, provide customer support and troubleshoot problems as permitted by law. We reserve the right to terminate the accounts of visitors who use these services in a manner inconsistent with the Terms of Service.</p>
      <p className="content-p">
        <span className="content-p-subtitle">(4) Service Use, Cookies and Other Similar Technologies</span>
        When you use our Services, we monitor and analyze web traffic and keep track of your behavior while using our Services. We may receive the information about your IP address, proxy server and operating system, the web browser, or your mobile carrier and location based on phone settings. Please, specifically refer to our Cookie Policy for further details as to our use of cookies and  similar technologies.</p>
      <p className="content-p">
        <span className="content-p-subtitle">(5) Billing Information</span>
        Details of transactions you carry out through our Website (as ID, date and time of transaction).</p>
      <p className="content-p">
        <span className="content-p-subtitle">(6) Sensitive Data</span>
        Unless you expressly agree, Quiz Easy does not process your sensitive data.</p>


      <h2 className="content-h2">4. Data We Collect: Purposes</h2>
      <p className="content-p">We may process the personal data you provided us voluntarily for the purposes defined below. From our perspective, we process your personal data to personalize the use of Quiz Easy to the fullest extent possible and to provide you with high-quality Services under the Terms of Service.</p>
      <p className="content-p">
        <span className="content-p-subtitle">(1) Provision of Services</span>
        Primarily, we process your personal data to authorize access to our Services and to fulfill our obligations under the Terms of Service for Services provision. In order for you to use the Services as a Student, you will need to provide payment (e.g. credit card details) and billing information through our partners – third-party payment providers, which process your personal data encrypted and securely.</p>
      Please note, that without the information provided by you under our Terms of Service, we will be unable to provide you with the functionality of our platform, connect you with other users, allow you to communicate with them and take/give lessons.
      <p className="content-p">
        <span className="content-p-subtitle">(2) Payment Services</span>
        We use third-party payment providers to bill you through an online account payment in lieu of directly processing your credit/debit card information. Your paid balance for one or more lessons is safe in your account as we use an integrated iframe for checkouts from our partners payment providers. Correspondingly all transactions are secured as our partners-payment providers use SSL encryption protection. We do not store your payment information on our servers.</p>
      <p className="content-p">
        <span className="content-p-subtitle">(3) Communications</span>
        We may contact you via email/SMS for Services-related purposes (welcome letter, security and legal notices, announcements, Quiz Easy updates, reminders, suggestions, etc.). We may also use your data to enable you to communicate with other users through Quiz Easy, including exchanging messages, conducting video calls, and/or leaving feedback about other users. Your contact data may also be used for gathering feedback. We may contact you to participate in research to improve the user experience of our product. Having received the communications from us, you are always able to opt out of such communications in each message, excluding legal notices, or changes of user settings on Quiz Easy.</p>
      <p className="content-p">
        <span className="content-p-subtitle">(4) Marketing and Promotion of Quiz Easy</span>
        We may process your first name, last name and email/phone number and send you messages with some commercial or promotional information about our Services. But don’t worry, we will request your consent first, and you may always opt out of our marketing messages, using the mechanisms described under the respective link in each message, and through the user settings available on Quiz Easy.</p>
      <p className="content-p">
        <span className="content-p-subtitle">(5) Analytics and Development</span>
        In order to find the points of interest in our Services and for the purposes of analytics, we monitor and analyze web traffic and keep track of your behavior within our Services. This enables us to provide services that are relevant to you and boost the quality of our Services. The data concerned within this activity are Cookies and Usage data, such as the parts of our Services you visit, the date and duration of your visit, and type of the device you used during your visit. All your data stated in this paragraph is collected and further processed in a manner that does not allow your identification. For these purposes, we use Google Analytics for Firebase, Hotjar Heat Maps & Recordings, Amplitude Analytics, Branch.io, Braze, and Intercom, the use of which ensures compliance with our privacy commitments. We may also carry out polls and surveys through Quiz Easy using external GDPR-compliant services. You are not obliged to respond to them and you may opt-out of such polls and surveys through user settings. In order to inform, optimize and serve you relevant advertising based on your past use of our Services, we may process your Usage Data and Cookies. Please, read our Cookie Policy for additional information.</p>
      <p className="content-p">
        <span className="content-p-subtitle">(6) Freelancer Verification and Program Preparation</span>
        To ensure a high quality of lessons given by freelancers on Quiz Easy, we collect and process freelancers’ credentials to verify their eligibility to teach subjects claimed by them. Besides that, we cooperate with the freelancers and use their information to help them develop the learning programs.</p>
      <p className="content-p">
        <span className="content-p-subtitle">(7) Compliance with Legal Requirements</span>
        We process certain payment information to comply with applicable legal requirements in the field of finances and/or accounting. We also may process the data collected on the platform in connection with legal requests we receive from you or any other competent person and/or body, and we may use this information to exercise, defend and/or establish our legal rights.</p>
      <p className="content-p">
        <span className="content-p-subtitle">(8) Fraud Prevention</span>
        Certain information, such as your log information, payment information and your behavior on the platform is subject to our supervision to prevent fraudulent activities on the platform and assist you in managing your user sessions.</p>
      <p className="content-p">
        <span className="content-p-subtitle">(9) Changes</span>
        If Quiz Easy decides to change the purposes of processing specified above, we will inform you about such changes prior to the use of your personal data within the newly set purposes. The notification on such changes will be sent to your email/phone number and you will have to provide your consent for the amended purposes unless the additional purpose of processing is compatible with those listed above. In case you do not want to provide us with the requested personal data for the purposes specified above, you may not have access to certain functions of the Services.</p>

      <h2 className="content-h2">5. How Is Personal Data Shared?</h2>
      <p className="content-p">In order to provide high-quality services, Quiz Easy hires people, enters into agreements with independent contractors as well as cooperates with other services providers, companies and organizations strictly under data processing agreement (DPA). For those reasons, some of your personal data can be passed to the persons mentioned. Quiz Easy uses only secure places of storage, such as those provided by AWS (Amazon, the USA), and other services and applications.</p>

      <h2 className="content-h2">6. How Long Will the Personal Data Be Processed and Stored by Quiz Easy?</h2>
      <p className="content-p">Your personal data will be stored by Quiz-Easy no longer than is necessary for the purposes for which the personal data is processed. Generally, Quiz Easy retains your personal data as long as your account is active. In case you decide to delete your account Quiz Easy retains your personal data no longer than 90 days.</p>
      <p className="content-p">However, in case of conflict situations in progress Quiz Easy may store personal data for 180 days period or more if the processing is necessary for the establishment, exercise or defense of legal claims and for compliance with a legal obligation that requires processing by Union or Member State law to which the controller is subject in case of an expired account or in case data subject requested to delete his personal data. Upon the expiry of this period or earlier, if a conflict situation is settled, all personal data gets deleted.</p>

      <h2 className="content-h2">7. Your Rights as Data Subject Regarding Your Personal Data Quiz Easy Processes</h2>
      <p className="content-p">
        <span className="content-p-subtitle">(1) Right of Confirmation</span>
        You can obtain confirmation from Quiz Easy as to whether personal data concerning you is being processed.</p>
      <p className="content-p">
        <span className="content-p-subtitle">(2) Right of Access</span>
        You can access your personal data processed by Quiz Easy.</p>
      <p className="content-p">
        <span className="content-p-subtitle">(3) Right to Rectification</span>
        You can ask Quiz Easy to have incomplete personal data requiring corrections completed.</p>
      <p className="content-p">
        <span className="content-p-subtitle">(4) Right to Erasure (Right to Be Forgotten)</span>
        You can request Quiz Easy to erase personal data. In most cases, Quiz Easy will erase it unless otherwise required by legislation. To delete your personal data, you can contact us by email at <a href={`mailto:${REACT_APP_CONTACT_EMAIL}`} className="content-link">{REACT_APP_CONTACT_EMAIL}</a>.</p>
      <p className="content-p">
        <span className="content-p-subtitle">(5) Right of Restriction of Processing</span>
        You can contest the accuracy of your personal data or in case Quiz Easy is not interested in processing your personal data any longer, but you want Quiz Easy to do this for different reasons, for example, to bring a claim against somebody and, instead of the erasure of information, its processing will be restricted.</p>
      <p className="content-p">
        <span className="content-p-subtitle">(6) Right to Data Portability</span>
        You can have your personal data transmitted directly from one controller to another, where technically feasible and when doing so does not adversely affect the rights and freedoms of others.</p>
      <p className="content-p">
        <span className="content-p-subtitle">(7) Right to Object to Processing of Your Personal Data by Quiz Easy</span>
        You can object to our processing of your personal data when the processing is related to the performance of our task carried in the public interest or the exercise of official authority vested in us. The other case is if we process your data for the purposes of the legitimate interests pursued by us or by a third party and you believe that such interests are overridden by your interests or fundamental rights and freedoms. If you make a request with objection to processing, we will no longer process the personal data unless we can demonstrate compelling legitimate grounds for the processing.</p>
      <p className="content-p">
        <span className="content-p-subtitle">(8) Right to Withdraw Consent at any Time by Contacting Us</span>
        After we at Quiz Easy receive a withdrawal request from you, we will process it in a timely manner and we will no longer process your personal data unless otherwise is set by law.</p>
      <p className="content-p">If one of the aforementioned reasons applies, please contact us, as instructed at the end of this Privacy Policy. We shall promptly ensure that the request is complied with immediately. These requests are free of charge. If you believe that our use of your data violates your rights, you can lodge a complaint with the competent data protection authority. Please note that when the processing of your personal data is carried for direct marketing purposes, you have the right to object at any time to such processing without providing any justification and we will no longer process your data with such direct marketing purposes.</p>


      <h2 className="content-h2">8. Legal Matters</h2>
      <p className="content-p">Quiz Easy considers your use of its Service to be private. However, we may disclose your personal data stored in our databases, in order to:</p>
      <p className="content-p">
        <span className="content-p-subtitle">(1) comply with the law or legal process served on us;</span>
      </p>
      <p className="content-p">
        <span className="content-p-subtitle">(2) enforce and investigate potential violations of this Privacy Policy; including use of this Service to participate in, or facilitate activities that violate the law;</span>
      </p>
      <p className="content-p">
        <span className="content-p-subtitle">(3) investigate potential fraudulent activities; or</span>
      </p>
      <p className="content-p">
        <span className="content-p-subtitle">(4) protect the rights, property, or safety of Quiz Easy, its employees, its customers or the public.</span>
      </p>

      <p className="content-p">We hope this Privacy Policy helped you to understand how your personal data will be dealt with here at Quiz Easy We will take care of your personal data, so you can use our Services and not be concerned about your privacy. Should you have anything to tell us, email us at <a href={`mailto:${REACT_APP_CONTACT_EMAIL}`} className="content-link">{REACT_APP_CONTACT_EMAIL}</a>. We will be happy to receive any information or comment from you!</p>
  </ContentPage>
)
