import React from 'react';
import classnames from 'classnames/index';

import { Header } from '../../blocks/Header';
import { Footer } from '../../blocks/Footer';
import Holder from '../../blocks/Holder';
//import {useMobile} from "../../../../hooks";
import './content-page.css';


const ContentPage = ({ className, children }) => {
  //const isMobile = useMobile();
  return (
    <>
      <Header />
      <main className="main content-page">
        <Holder type={1} className={classnames(className)}>
          {children}
        </Holder>
      </main>
      <Footer />
    </>
  )
};

export default ContentPage
