import React, { useEffect } from 'react';

import useForm from "../../hooks/useForm";

//import {useMobile} from "../../../../hooks";
import ContentPage from '../ContentPage';
import "./join-network.css";
import { REACT_APP_CONTACT_EMAIL } from '../../../../config';

export default function ReportAbuse() {
  //const isMobile = useMobile();
  //const { pageCfg } = props;

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
  } = useForm(submit, validate);

  useEffect(() => {
  });

  function submit() {
    window.location = encodeURI(`mailto:${REACT_APP_CONTACT_EMAIL}?subject=Join Network Application&body=my name:${values.name ? values.name : ''} 
my email: ${values.email}
my specialization: ${values.specialization ? values.specialization : ''}`);
  }

  function validate(values) {

    let errors = {};
    if (!values.name) {
      //errors.task_number = 'This field is mandatory!';
    }
    if (!values.email) {
      errors.email = 'This field is mandatory!';
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
      errors.email = 'You have entered an invalid email address.';
    }

    if (!values.specialization) {
      //errors.info= 'This field is mandatory!'
    }

    return errors;
  }

  return (
    <ContentPage noWrap className={'join-page'}>
      <h1 className="title join-title">JOIN QUIZ EASY NETWORK</h1>
      <div className="join-msg">Do you like making tasks/quizzes easy to understand?<br/>
          Do you have 3 years of online tutoring exp.?<br/>
          Do you wish to choose your own hours of work?</div>
      <div className="join-msg">If you have three <span className="strong">"YES"</span>, welcome aboard!</div>
      <div className="join-subtitle">How to apply & get started</div>

      <div className="join-block">
          <div className="join-item join-item1">
            <img className="join-item-img" src={require('./img/ill1.svg')} alt="Quiz Easy — urgent help from experts with any online task"/>
            <div className="join-item-msg">Fill out a form and attach your CV after clicking "Submit".</div>
          </div>
          <div className="join-item join-item2">
            <img className="join-item-img" src={require('./img/ill2.svg')} alt="Quiz Easy — urgent help from experts with any online task"/>
            <div className="join-item-msg">Receive a preliminary result by email within 7 business days.</div>
          </div>
          <div className="join-item join-item3">
            <img className="join-item-img" src={require('./img/ill3.svg')} alt="Quiz Easy — urgent help from experts with any online task"/>
            <div className="join-item-msg">Pass a 3-stage qualification test and get approved to join!</div>
          </div>

        <form className="join-form" onSubmit={handleSubmit} noValidate>
          <div className="join-form-title">Fill out a form</div>
          <div className="form-item">
            <label htmlFor="name" className="form-label">Your name</label>
            <input
              id="name"
              type="text"
              className={`form-input ${errors.name && 'error'}`}
              placeholder="Type here"
              value={values.name || ''}
              onChange={handleChange}
            />
            {errors.name && (
              <div className="form-item-error">{errors.name}</div>
            )}
          </div>
          <div className="form-item">
            <label htmlFor="email" className="form-label">Your email address</label>
            <input
              id="email"
              type="email"
              className={`form-input ${errors.email && 'error'}`}
              placeholder="example@gmail.com"
              value={values.email || ''}
              onChange={handleChange}
              required
            />
            {errors.email && (
              <div className="form-item-error">{errors.email}</div>
            )}
          </div>
          <div className="form-item">
            <label htmlFor="specialization" className="form-label">Your specialization</label>
            <input
              id="specialization"
              type="text"
              className={`form-input ${errors.specialization && 'error'}`}
              placeholder="Type here"
              value={values.specialization || ''}
              onChange={handleChange}
            />
            {errors.specialization && (
              <div className="form-item-error">{errors.specialization}</div>
            )}
          </div>
          <button className="button join-submit-button" type="submit" onClick={handleSubmit}>Submit</button>
        </form>

      </div>
    </ContentPage>
  )
}

