import React, { useState } from 'react';

import { HashLink } from 'react-router-hash-link';
//import { NavLink } from 'react-router-dom';
import Swipe from 'react-easy-swipe';
import {CSSTransition} from "react-transition-group";
import classnames from 'classnames';

import { useMobile } from '../../../../hooks';
import Holder from '../Holder'
import Icon from '../Icons';

import './header.css';

export const Header = ({type}) => {
  const isMobile = useMobile();
  const [isMenuOpen, setMenuOpen] = useState(0);

  const onClickOpen = () => {
    setMenuOpen(true);
    document.querySelector('html').classList.add('fixed');
  };

  const onClickClose = () => {
    setMenuOpen(false);
    document.querySelector('html').classList.remove('fixed');
  };

  const onSwipeMove = (position) => {
    if (position.x <= -50) {
      onClickClose();
    }
  };

  const HeaderNavList = [
    { name: 'How It Works', link: '#how' },
    { name: 'About Us', link: 'about-us' },
    { name: 'Why Choose Us', link: '#why' },
  ];

  const HeaderNav = () => {
    return (
      <nav className='header-nav-list'>
        {HeaderNavList.map((item, key) => {
          return(
            <div className='header-nav-item' onClick={onClickClose} key={`hnavmob_${key}`}>
              <HashLink className='header-nav-link' smooth to={'/' + item.link}>{item.name}</HashLink>
            </div>
          )
        })}
      </nav>
    )
  };

  return (
    <header className={classnames('header', {[`header_${type}`]:type})}>
      <Holder type={1} className={'header-holder'}>
        <HashLink smooth to="/#hero">
          <img className="header-logo-icon" src={require('./img/logo-dark.svg')} alt="Quiz Easy — urgent help from experts with any online task"/>
        </HashLink>
        {isMobile ?
          <button className="header-nav-open-btn" onClick={onClickOpen}>
            <img className="header-nav-open-icon" src={require('./img/menu-icon.svg')} alt="Quiz Easy — urgent help from experts with any online task"/>
          </button>
          : <HeaderNav />
        }
      </Holder>
      {!!(isMobile && isMenuOpen) &&
      <CSSTransition
        in={isMenuOpen}
        timeout={300}
        classNames="hnav-mob-transition"
        unmountOnExit
      >
        <Swipe className='header-nav-mob' onSwipeMove={onSwipeMove} >
          <Holder type={1} className={'header-nav-holder'}>
            <button className="header-nav-close-btn" onClick={onClickClose}>
              <Icon className={'header-nav-close-icon'} iconName="close-icon" />
            </button>
            <HeaderNav />
          </Holder>
        </Swipe>
      </CSSTransition>
      }
    </header>
  );
};
