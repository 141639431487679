import React from 'react';
//import { NavLink } from 'react-router-dom';
//import { Link } from 'react-router-dom';

//import {useMobile} from "../../../../hooks";
import ContentPage from '../ContentPage';
import "./about.css";

export default () => {
  //const isMobile = useMobile();
  //const { pageCfg } = props;
  return (
    <ContentPage noWrap className={'about-page'}>
      <div className="about-block">
        <img className="about-img" src={require('./img/about-ill.svg')} alt="Quiz Easy — urgent help from experts with any online task"/>
          <div className="about-column">
            <h1 className="title about-title">Why We Do<br /> What We Do</h1>
            <div className="about-subtitle">When the worldwide crisis forced education to shift online, students had to adjust to a big workload of dashboard tasks, tests, quizzes, and other online tasks. As they had a decreased direct contact with professors, 90% of students reported feeling constant anxiety about failing the course. That’s when we decided to step in and help make any online task or quiz easy to understand!</div>
          </div>
      </div>
      <div className="about-list">
        <div className="about-item">
          <div className="about-item-title">Our Network</div>
          <div className="about-item-message">We gathered the most enthusiastic freelance educators from all around the world to join our network. They all have vast experience in online teaching and know how to get prepared and handle all types of dashboard tasks and quizzes.</div>
        </div>
        <div className="about-item">
          <div className="about-item-title">Our Mission</div>
          <div className="about-item-message">We make a contribution to the level of education of our and future generations. Becoming a go-to service for every student seeking professional assistance so that they finally say "quiz? easy!" to any task is what we aim for.</div>
        </div>
        <div className="about-item">
          <div className="about-item-title">Our Methods</div>
          <div className="about-item-message">We provide 24/7 online help with college tasks that varies in style, format, and content depending on the request. Mostly we help students work through homework challenges, prepare for tests, learn how to pass any quiz easily, etc.</div>
        </div>
      </div>
    </ContentPage>
  )
}

