import { useEffect } from 'react';
import getTracker from '@verdaccio/lrtrack-js';

import {
  REACT_APP_LR_TRACK_URL,
  REACT_APP_LR_TRACK_ID,
  IS_PROD,
} from './config';

const tracker = getTracker({
  trackerId: REACT_APP_LR_TRACK_ID,
  baseUrl: REACT_APP_LR_TRACK_URL,
  isDebug: !IS_PROD,
});

const useTrackValues = tracker.metrics.getUseTrackValues({ useEffect });
const trackClicks = tracker.metrics.trackClicks;
const trackValues = tracker.metrics.trackValues;

const storageGetItem = (key, req) =>
  tracker.utils.storageGetItem(tracker.utils.getStorageKey(key), req);

const storageSetItem = (key, payload, res) => {
  tracker.utils.storageSetItem(tracker.utils.getStorageKey(key), payload, res);
};

const setCid = (cid) => tracker.config.setCid(cid);

export {
  tracker as default,
  useTrackValues,
  trackClicks,
  trackValues,
  storageGetItem,
  storageSetItem,
  setCid,
};
