import * as actionTypes from './../actionTypes';

export default (
  state = {
    data: {},
    isPhoneError: '',
    isPosting: false,
    isPatching: false,
    error: null,
  },
  action
) => {
  switch (action.type) {
    case actionTypes.VALIDATE_PHONE_REQUEST:
      return {
        ...state,
        isPosting: true,
      };
    case actionTypes.VALIDATE_PHONE_RECEIVE:
      return {
        ...state,
        isPhoneError: action.data.error,
        isPosting: false,
      };
    case actionTypes.VALIDATE_PHONE_ERROR:
      return {
        ...state,
        error: action.error,
        isPosting: false,
      };
    case actionTypes.UPDATE_USER_REQUEST:
      return {
        ...state,
        isPatching: true,
      };
    case actionTypes.UPDATE_USER_RECEIVE:
      return {
        ...state,
        isPatching: false,
      };
    case actionTypes.UPDATE_USER_ERROR:
      return {
        ...state,
        isPatching: false,
        error: action.error,
      };
    default:
      return state;
  }
};
