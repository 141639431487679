import React from 'react';
import ContentPage from './../ContentPage';

import './index.css';

/*
import {
  REACT_APP_CONTACT_EMAIL,
} from './../../../../config';
*/

export default () => (
	  <ContentPage>
			<h1 className="content-title">QUIZ EASY COOKIE POLICY</h1>
			<p className="content-p">We would like you to know more about our use of cookies and this document explains what cookies are, how we use cookies, your choices regarding cookies and further information about cookies. This Cookie Policy is a part of our Terms of Service and Privacy Policy, together with your consents comprising our agreement with you. By using the Services and clicking the respective buttons on our banners, you consent to the use of cookies for the purposes we describe in this policy.</p>
			<p className="content-p">Kinds of cookies and purposes Quiz Easy uses them:
				<ul className="content-ul">
					<li>to enhance your user experience;</li>
					<li>to enable us to see your actions and preferences on the Services, analyze this information and provide you more relevant information on the Services you are currently using or may use;</li>
					<li>to enable behavioral advertising.</li>
				</ul>
			</p>

			<p className="content-p">Cookies are those small files comprising bits of text that are installed on your computer or mobile device each time you open the respective Service. They enable our server to provide you with the information that is customized with your needs when you use some service for the next time. Usually your browser tells our systems if any cookie files were installed in your computer and as a result, we may analyze the information the cookie files give us.</p>
			<p className="content-p">More specifically, here are the categories of cookies and description of their nature and purposes:</p>

			<table className="content-table">
				<tr>
					<th>Categories of Use</th>
					<th>Description</th>
				</tr>
				<tr>
					<td>Essentials</td>
					<td>We use such cookies to provide functionality that help us deliver products and Services. For example, cookies may store login information that helps users enter the website easily. We may also use cookies and similar technologies to help us provide you with social plugins, other customized content and experiences, such as making suggestions to you and others.</td>
				</tr>
				<tr>
					<td>Marketing</td>
					<td>Quiz Easy may use cookies in order to provide you with more relevant advertising when you use the Services. Cookies will enable us to know more about your prior visits on Quiz Easy and your activity within Quiz Easy, including the date and time of visits, time spent on the Website as well as the pages viewed and websites previously visited. Quiz Easy may process such data through the external services, including but not limited to Adwords.</td>
				</tr>
				<tr>
					<td>Performance</td>
					<td>We need cookies to understand how you access and use Quiz Easy. When we track platform performance, we aggregate data that was gathered by our tracking scripts to analyze and improve platform performance. We use scripts like Google Analytics and Hotjar to analyze performance of the website. These third-party scripts collect data on your usage of the platform such as the device you use, its brand name, operating system, screen resolution, duration and time of your visit, the time you spent on each page you visited, your IP address, and links you clicked.</td>
				</tr>
				<tr>
					<td>Functional</td>
					<td>Cookies can tell us which language you prefer and what your communications preferences are.</td>
				</tr>
			</table>

			<p className="content-p">If you'd like to delete cookies or instruct your web browser to delete or refuse cookies, please visit the help pages of your web browser as specified below (separate support pages for each browser). Please note, however, that if you delete cookies or refuse to accept them, you might not be able to use all of the features we offer, you may not be able to store your preferences, and some of our pages might not display properly, since the Services will no longer be personalized to you. It may also stop you from saving customized settings. If you use Quiz Easy without changing your browser settings, we’ll assume that you’re happy to receive all cookies within our Services.</p>

			<p className="content-p">You can learn more about cookies and the following third-party websites:</p>
			<p className="mt16">
				<a href="https://www.networkadvertising.com/" className="content-link" target="_blank" rel="noopener noreferrer">http://www.networkadvertising.com</a>
			</p>
			<p className="mt8">
				<a href="http://www.allaboutcookies.org" className="content-link" target="_blank" rel="noopener noreferrer">http://www.allaboutcookies.org</a>
			</p>
	</ContentPage>
)
