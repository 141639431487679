import Landing from './pages/Landing';
import About from './pages/About';
import ReportAbuse from './pages/ReportAbuse';
import JoinNetwork from './pages/JoinNetwork';

import HonorCode from './pages/HonorCode';
import Cookies from './pages/Cookies';
import Privacy from './pages/Privacy';
import Refunds from './pages/Refunds';
import Terms from './pages/Terms';

import Files from './pages/Files';
import Redirect from './pages/Redirect'

import NotFound from './pages/NotFound';

//import { IS_PROD } from '../../config';

import './assets/css/index.css';

export const viewport = 'width=device-width';

export const routes = [
  {
    path: '/',
    meta: {
      title: 'Quiz Easy service | Urgent help from experts with online tasks',
      description: 'Quiz Easy is a text based service that matches you with freelance educators to get personalized help with any college tests, quizzes, and other online tasks.',
      keywords: 'quiz easy, quizeasy, quiz-easy, quiz easy service, quiz easy network, quiz easy reviews, homework help, online homework help, math homework help online, website that helps with homework',
      robots: 'index, follow',
    },
    component: Landing,
    exact: true,
    pageCfg: {},
  },
  {
    path: '/thank-you',
    meta: {
      title: 'Quiz Easy service | Thank you',
      description: '',
      keywords: '',
      robots: 'noindex, nofollow',
    },
    component: Landing,
    exact: true,
    pageCfg: {
      thankYouModal: true
    },
  },

  {
    path: '/about-us',
    meta: {
      title: 'Quiz Easy service | About Us',
      description: 'After a shift to online learning we gathered top level experts in different subjects to help students adjust to the new education format and boost performance!',
      keywords: 'quiz easy, quiz-easy, quizeasy, quiz easy service, quiz easy network, quiz easy reviews, homework help, online homework help, math homework help online, website that helps with homework',
      robots: 'index, follow',
    },
    component: About,
    pageCfg: {},
  },
  {
    path: '/join-network',
    meta: {
      title: 'Quiz Easy service | Join Network',
      description: 'Quiz Easy is always looking for experts in different fields of study to join our network. Apply if you have relevant experience and strong teaching skills!',
      keywords: 'quiz easy, quiz-easy, quizeasy, quiz easy service, quiz easy network, quiz easy reviews, homework help, online homework help, math homework help online, website that helps with homework',
      robots: 'index, follow',
    },
    component: JoinNetwork,
    pageCfg: {},
  },
  {
    path: '/honor-code',
    meta: {
      title: 'Quiz Easy service | Honor Code',
      description: 'Please read our Honor Code prior to using the website. It reflects the values of our company and states our expectations regarding users’ behavior.',
      keywords: 'quiz easy, quiz-easy, quizeasy, quiz easy service, quiz easy network, quiz easy reviews, homework help, online homework help, math homework help online, website that helps with homework',
      robots: 'index, follow',
    },
    component: HonorCode,
    pageCfg: {},
  },
  {
    path: '/report-abuse',
    meta: {
      title: 'Quiz Easy service | Report Abuse',
      description: 'You can report the case of unethical behavior here. Your anonymous claim will make a huge contribution to maintaining high standards of our business.',
      keywords: 'quiz easy, quiz-easy, quizeasy, quiz easy service, quiz easy network, quiz easy reviews, homework help, online homework help, math homework help online, website that helps with homework',
      robots: 'index, follow',
    },
    component: ReportAbuse,
    pageCfg: {},
  },

  {
    path: '/privacy-policy',
    meta: {
      title: 'Quiz Easy service | Privacy Policy',
      description: 'We are committed to protecting your personal data. Please read our Privacy Policy explaining what user info we collect and how we are using it.',
      keywords: 'quiz easy, quiz-easy, quizeasy, quiz easy service, quiz easy network, quiz easy reviews, homework help, online homework help, math homework help online, website that helps with homework',
      robots: 'index, follow',
    },
    component: Privacy,
    pageCfg: {},
  },
  {
    path: '/refund-policy',
    meta: {
      title: 'Quiz Easy service | Refund policy',
      description: 'Learn more about how our refund policy works and how to request a refund of your payment in case you were not happy with the service or changed your mind.',
      keywords: 'quiz easy, quiz-easy, quizeasy, quiz easy service, quiz easy network, quiz easy reviews, homework help, online homework help, math homework help online, website that helps with homework',
      robots: 'index, follow',
    },
    component: Refunds,
    pageCfg: {},
  },
  {
    path: '/cookie-policy',
    meta: {
      title: 'Quiz Easy service | Cookie Policy',
      description: 'We would like you to know more about how we use cookies, your choices regarding cookies and further information on the matter.',
      keywords: 'quiz easy, quiz-easy, quizeasy, quiz easy service, quiz easy network, quiz easy reviews, homework help, online homework help, math homework help online, website that helps with homework',
      robots: 'index, follow',
    },
    component: Cookies,
    pageCfg: {},
  },
  {
    path: '/terms-of-service',
    meta: {
      title: 'Quiz Easy service | Terms of Service',
      description: 'Please read Quiz Easy terms of service carefully prior to using our website. We reserve the right to make changes and update these terms on the website.',
      keywords: 'quiz easy, quiz-easy, quizeasy, quiz easy service, quiz easy network, quiz easy reviews, homework help, online homework help, math homework help online, website that helps with homework',
      robots: 'index, follow',
    },
    component: Terms,
    exact: true,
    pageCfg: {},
  },

  {
    path: '/files/:userId',
    meta: {
        title: 'Share files with Quiz Easy',
        description: '',
        keywords: '',
    },
    component: Files,
    exact: true,
    pageCfg: {},
  },

  {
    path: '/trustpilot',
    meta: {
      title: 'Quiz Easy Trustpilot Reviews',
      description: 'Leave your review about Quiz Easy on Trustpilot. We value your opinion!',
      keywords: '',
      robots: 'noindex, nofollow',
    },
    component: Redirect,
    exact: true,
    pageCfg: {
      redirectLocation: 'https://www.trustpilot.com/evaluate/quiz-easy.com'
    },
  },
  {
    path: '/sitejabber',
    meta: {
      title: 'Quiz Easy Sitejabber Reviews',
      description: 'Leave your review about Quiz Easy on Sitejabber. We value your opinion!',
      keywords: '',
      robots: 'noindex, nofollow',
    },
    component: Redirect,
    exact: true,
    pageCfg: {
      redirectLocation: 'https://www.sitejabber.com/online-business-review?url=quiz-easy.com'
    },
  },
  {
    path: '/facebook',
    meta: {
      title: 'Quiz Easy Facebook Reviews',
      description: 'Leave your review about Quiz Easy on Facebook. We value your opinion!',
      keywords: '',
      robots: 'noindex, nofollow',
    },
    component: Redirect,
    exact: true,
    pageCfg: {
      redirectLocation: 'https://www.facebook.com/quizeasy/reviews'
    },
  },

  {
    meta: {
      title: 'Error',
      description: 'Error page',
      keywords: '',
      robots: 'noindex, nofollow',
    },
    component: NotFound,
    pageCfg: {},
  },
];
