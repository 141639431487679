import { getLastYears, isClient } from './utils';

export const REACT_APP_ENV = process.env.REACT_APP_ENV;

if (!REACT_APP_ENV) {
  throw new Error(
    'REACT_APP_ENV not specified in npm run start:<env>, see package for details.'
  );
}

export const REACT_APP_BUILD_ID = process.env.REACT_APP_BUILD_ID;

console.info(`REACT_APP_ENV - ${REACT_APP_ENV}`);
console.info(`REACT_APP_BUILD_ID - ${REACT_APP_BUILD_ID}`);

export const IS_PROD = REACT_APP_ENV === 'prod';
export const IS_STAGE = REACT_APP_ENV === 'stage';

export const IS_DEV =
  REACT_APP_ENV === 'dev' ||
  (isClient && /localhost/.test(window.location.hostname));

export const REACT_APP_HOST = process.env.REACT_APP_HOST;
export const REACT_APP_SMS_TOOL_NAME = process.env.REACT_APP_SMS_TOOL_NAME;
export const REACT_APP_SMS_TOOL_HOST = process.env.REACT_APP_SMS_TOOL_HOST;

export const REACT_APP_GTM_APP_ID = process.env.REACT_APP_GTM_APP_ID;
export const REACT_APP_GTM_APP_ID2 = process.env.REACT_APP_GTM_APP_ID2;

export const REACT_APP_HERO_TITLE = process.env.REACT_APP_HERO_TITLE;
export const REACT_APP_HERO_SUBTITLE = process.env.REACT_APP_HERO_SUBTITLE;

export const REACT_APP_TEXT_BACK_INFO = process.env.REACT_APP_TEXT_BACK_INFO;
export const REACT_APP_TEXT_BACK_AGREE = process.env.REACT_APP_TEXT_BACK_AGREE;
export const REACT_APP_TEXT_BACK_TERMS = process.env.REACT_APP_TEXT_BACK_TERMS;
export const REACT_APP_TEXT_BACK_PRIVACY =
  process.env.REACT_APP_TEXT_BACK_PRIVACY;
export const REACT_APP_TEXT_BACK_COOKIE =
  process.env.REACT_APP_TEXT_BACK_COOKIE;

export const REACT_APP_TEXT_BACK_TEXT = process.env.REACT_APP_TEXT_BACK_TEXT;
export const REACT_APP_TEXT_BACK_TEXT_LEAD_EXIST =
  process.env.REACT_APP_TEXT_BACK_TEXT_LEAD_EXIST;

export const REACT_APP_CONTACT_EMAIL = process.env.REACT_APP_CONTACT_EMAIL;
export const REACT_APP_PP_EMAIL = process.env.REACT_APP_PP_EMAIL;
export const REACT_APP_ADDRESS_UK = process.env.REACT_APP_ADDRESS_UK;
export const REACT_APP_ADDRESS_US = process.env.REACT_APP_ADDRESS_US;
export const REACT_APP_SITE_ID = process.env.REACT_APP_SITE_ID;
export const REACT_APP_PHONE = process.env.REACT_APP_PHONE;
export const REACT_APP_LANDING_GUID = process.env.REACT_APP_LANDING_GUID;
export const REACT_APP_PHONE_MASK = process.env.REACT_APP_PHONE_MASK;
export const REACT_APP_PHONE_TEXT = process.env.REACT_APP_PHONE_TEXT;
export const REACT_APP_LOCALES = process.env.REACT_APP_LOCALES;

export const REACT_APP_LOGO_NAME = process.env.REACT_APP_LOGO_NAME;
export const REACT_APP_LOGO_NAME_DARK = process.env.REACT_APP_LOGO_NAME_DARK;
export const REACT_APP_SITENAME = process.env.REACT_APP_SITENAME;
export const REACT_APP_TEXTBACK_FLAG = process.env.REACT_APP_TEXTBACK_FLAG;
export const REACT_APP_FACEBOOK_PAGE = process.env.REACT_APP_FACEBOOK_PAGE;

export const REACT_APP_TWITTER_LINK = process.env.REACT_APP_TWITTER_LINK;
export const REACT_APP_FB_LINK = process.env.REACT_APP_FB_LINK;

export const REACT_APP_FLAGS = process.env.REACT_APP_FLAGS;

export const REACT_APP_HOTJAR_ID = process.env.REACT_APP_HOTJAR_ID;

export const REACT_APP_USE_MODAL_CHANGE_LOCALE =
  process.env.REACT_APP_USE_MODAL_CHANGE_LOCALE;

export const REACT_APP_QUORA_ID = process.env.REACT_APP_QUORA_ID;
export const REACT_APP_GOPTIMISE_ID = process.env.REACT_APP_GOPTIMISE_ID;
export const REACT_APP_FB_LINKS = process.env.REACT_APP_FB_LINKS;
export const REACT_APP_GTAG_ID = process.env.REACT_APP_GTAG_ID;

export const REACT_APP_LR_TRACK_ID = process.env.REACT_APP_LR_TRACK_ID;
export const REACT_APP_LR_TRACK_URL = process.env.REACT_APP_LR_TRACK_URL;

export const DSH_HOST = 'https://ana.dsh-agency.com';

export const landingsToSave = [
  '/students-1',
  '/students-2',
  '/students-3',
  '/students-4',
  '/students-5',
];

export const COUNTRIES = [
  {
    countryCode: 'us',
    value: 'USA',
  },
  // {
  //   countryCode: 'gb',
  //   value: 'United Kingdom',
  // },

  {
    countryCode: 'ca',
    value: 'Canada',
  },
  /*
  {
    countryCode: 'au',
    value: 'Australia',
  },
*/
  // {
  //   countryCode: 'in',
  //   value: 'India (भारत)',
  // },
  // {
  //   countryCode: 'br',
  //   value: 'Brazil (Brasil)',
  // },
  // {
  //   countryCode: 'ar',
  //   value: 'Argentina',
  // },

  /*
  {
    countryCode: 'nz',
    value: 'New Zealand',
  },
  {
    countryCode: 'europe',
    value: 'Europe',
  },
  {
    countryCode: 'other',
    value: 'Other',
  },
*/

  // {
  //   countryCode: 'nz',
  //   value: 'New Zealand',
  // },
  // {
  //   countryCode: 'sg',
  //   value: 'Singapore',
  // },
  // {
  //   countryCode: 'kr',
  //   value: 'South Korea',
  // },
  // {
  //   countryCode: 'hk',
  //   value: 'Hong Kong',
  // },
  // {
  //   countryCode: 'ae',
  //   value: 'United Arab Emirates',
  // },
  // {
  //   countryCode: 'sa',
  //   value: 'Saudi Arabia',
  // },
  // {
  //   countryCode: 'qa',
  //   value: 'Qatar',
  // },
  // {
  //   countryCode: 'kw',
  //   value: 'Kuwait',
  // },
  // {
  //   countryCode: 'bh',
  //   value: 'Bahrain',
  // },
  // {
  //   countryCode: 'ye',
  //   value: 'Yemen',
  // },
  // {
  //   countryCode: 'om',
  //   value: 'Oman',
  // },
];

export const LEVELS = [
  { value: 'High School' },
  { value: 'College/University' },
  { value: 'Masters' },
  { value: 'PhD' },
];

export const YEARS = getLastYears(2022, 53);

//cookie popup google consent mode 2
export const countryCodesArray = ["AT", "BE", "BG", "HR", "CY", "CZ", "DK", "EE", "FI", "FR", "DE", "GR", "HU", "IE", "IT", "LV", "LT", "LU", "MT", "NL", "PL", "PT", "RO", "SK", "SI", "ES", "SE", "IS", "LI", "NO", "CH", "GB"];
