import React, { Component } from 'react';
import classnames from 'classnames';
import { LocalesSwitcherHOC } from '../../../../../base_components/Locales';
import Icons from '../../Icons';
import './input-select.css';

export default class InputSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      countryCode: props.countryCode || '',
      value: props.value || '',
      isOpen: false,
    };

    this.onOpen = this.onOpen.bind(this);
    this.onChangeCountry = this.onChangeCountry.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { value, countryCode } = this.props;

    if (!value && value !== prevProps.value) {
      this.setState({
        value: '',
      })
    }

    if (countryCode !== prevProps.countryCode
      && value !== prevProps.value) {
      this.setState({
        value,
        countryCode,
      })
    }
  }

  handleOutsideClick(e) {
    if (this.listRef && !this.listRef.contains(e.target)) {
      this.setState({
        isOpen: false,
      });
    }
  }

  onOpen() {
    this.setState({
      isOpen: !this.state.isOpen,
    }, () => {
      document.addEventListener('click', this.handleOutsideClick);
    });
  }

  onChangeCountry(item) {
    this.props.onChange({
      target: {
        name: this.props.name,
        value: item.value.toString(),
      }
    });

    this.setState({
      ...item,
      isOpen: false,
    });
  }

  render() {
    const { name, items, isError, className, } = this.props;
    const { value, isOpen, countryCode } = this.state;
    const isDropdown = items && items.length > 1;
    const isScroll = items && items.length > 5;

    const isCountrySelect = items.some(item => item.countryCode);
    const selectedCountryCode = isCountrySelect && (countryCode || items.filter(item => item.countryCode)[0].countryCode);

    return (
      <div className={classnames(
        'input',
        'input-select', className,
        { [`input-select__${selectedCountryCode}`]: isCountrySelect },
        { 'input-select__countries': isCountrySelect },
        { 'input-select__dropdown': isDropdown },
        { 'input-select__scroll': isScroll },
        { 'input-select__open': isOpen },
        { 'input__error': isError },
      )}
        ref={node => (this.listRef = node)}
        onClick={isDropdown ? this.onOpen : () => { }}
      >
        {isCountrySelect &&
        <Icons className="flag-icon" iconName={`ic-flag-${selectedCountryCode}`} />
        }
        {/*<span className="input-select-value">{value || 'Select one'}</span>*/}
        {isDropdown &&
          <div className="input-select-list">
              {items.map((item, key) => {
                const selected = item.value === value;

                return (
                  <div className={classnames(
                    'input-select-list-item',
                    { [`input-select-list-item__${item.countryCode}`]: item.countryCode },
                    { 'input-select-list-item__selected': selected },
                  )}
                    onClick={() => this.onChangeCountry(item)}
                    key={`input-select-list-item-${key}`}
                  >
                    <Icons className="flag-icon" iconName={`ic-flag-${item.countryCode}`} />
                    <div className="country-name">{item.value}</div>
                  </div>
                );
              })}
          </div>
        }
        <input
          className={classnames('input', { 'input__error': isError })}
          type="hidden"
          name={name}
          onChange={(e) => { this.onChangeCountry({ country: e.target.value }) }}
          value={value}
        />
      </div>
    );
  }
}

export const InputSelectLocales = LocalesSwitcherHOC(InputSelect);
