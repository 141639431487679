import * as actionTypes from './../actionTypes';
import { localesData } from "./../../utils/locales";

const locale = Object.keys(localesData)[0];

export default (state = {
  data: {
    ...localesData[locale]
  },
  userLocale: '',
  locale: locale,
  isModalOpen: false,
  isLocaleSet: false,
}, action) => {
  switch(action.type) {
    case actionTypes.SET_LOCALE:
      return {
        ...state,
        locale: action.data,
      };
    case actionTypes.SET_LOCALE_DATA:
      return {
        ...state,
        data: action.data,
        isLocaleSet: true,
      };
    case actionTypes.GET_USER_RECEIVE:
      return {
        ...state,
        userLocale: action.data,
      };
    case actionTypes.GET_USER_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case actionTypes.SHOW_MODAL:
      return {
        ...state,
        isModalOpen: action.data,
      };
    default:
      return state
  }
};
