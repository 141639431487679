import { REACT_APP_HOST, IS_DEV, IS_PROD, DSH_HOST, REACT_APP_SITE_ID } from '../config';

export const getUserDataUrl = `${DSH_HOST}/ipinfo/me?geo=1`;

export const checkLeadExistUrl = IS_DEV
  ? `${REACT_APP_HOST}/api/v1/number/is_exists`
  : `https://api.dsh-agency.com/api/v1/number/is_exists`;

export const sentTextBackUrl = IS_PROD
  ? `https://connect-quiz.dsh-agency.com/api/v1/external/messages/textback`
  : `https://connect-quiz-stage.dsh-agency.com/api/v1/external/messages/textback`;

export const createLeadUrl = IS_DEV
  ? `${REACT_APP_HOST}/api/v3/users/client/new`
  : `https://api.dsh-agency.com/api/v3/users/client/new`;

export const validatePhoneUrl = IS_DEV
  ? `${REACT_APP_HOST}/api/v3/phone_validate`
  : `https://api.dsh-agency.com/api/v3/phone_validate`;

export const updateUserUrl = (id) =>
  IS_DEV
    ? `${REACT_APP_HOST}/api/v3/users/client/${id}`
    : `https://api.dsh-agency.com/api/v3/users/client/${id}`;

export const healthCheckUrl = () =>
  IS_DEV ? `${REACT_APP_HOST}/api/v1/pingdb` : `https://api.dsh-agency.com/api/v1/pingdb`;

export const makeFormData = (data) => {
  let formData = new FormData();
  Object.keys(data).forEach((item, key) => formData.append(item, data[item]));
  return formData;
};

const fetchWrapper = async (url, opts) => {
  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), 7000);

  const resp = await fetch(url, { ...opts, signal: controller.signal })
    .then((resp) => {
      if (resp.ok) {
        return resp.json();
      }

      return resp
        .json()
        .catch(() =>
          Promise.reject({
            status: resp.status,
            text: 'Request JSON error',
          })
        )
        .then((json) =>
          Promise.reject({
            status: resp.status,
            text: json.detail || json.error_message,
            type: json.error_type,
            value: json.error_value,
          })
        );
    })
    .catch((error) => {
      if (error instanceof Error) {
        return Promise.reject({ text: error.toString() });
      }

      if (typeof error === 'string' || error instanceof String) {
        return Promise.reject({ text: error });
      }

      if (error && typeof error === 'object' && error.constructor === Object) {
        const { status } = error;
        return Promise.reject({
          status,
          text: error.text,
          type: error.type,
          value: error.value,
          notAuthenticated: status === 401 || status === 403,
        });
      }

      return Promise.reject({
        text: 'Unknown error',
      });
    });

  clearTimeout(id);

  return resp;
};

/**
 * _fetch
 * @param {String} url
 * @param {Object} opts
 */

const _fetch = (url, opts) => {
  const mergedOpts = Object.assign(opts);

  return fetch(url, mergedOpts)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.error(error);
    });
};

/**
 * getUserData
 */
export const getUserData = () => {
  return _fetch(getUserDataUrl, {
    method: 'get',
  });
};

/**
 * checkLeadExists
 */

export const checkLeadExists = (phone) => {
  const data = {
    host: REACT_APP_SITE_ID,
    phone,
  };

  return _fetch(checkLeadExistUrl, {
    method: 'post',
    body: makeFormData(data),
  });
};

/**
 * sentTextBack
 * @param {Object} data
 */
export const sentTextBack = (data) => {
  return _fetch(sentTextBackUrl, {
    method: 'post',
    body: makeFormData(data),
  });
};

/**
 * createLead
 * @param {Object} data
 */
export const createLead = (data) => {
  return _fetch(createLeadUrl, {
    method: 'post',
    body: makeFormData(data),
  });
};

export const get = (url, opts) =>
  fetchWrapper(url, {
    method: 'GET',
    ...opts,
  });

export const post = (url, payload, opts) =>
  fetchWrapper(url, {
    method: 'POST',
    body: payload,
    ...opts,
  });

export const patch = (url, payload, opts) =>
  fetchWrapper(url, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
    ...opts,
  });

export const getUserIpInfo = () => {
  return fetch('https://ana.dsh-agency.com/ipinfo/me?geo=1')
    .then((resp) => {
      if (resp.ok) {
        return resp.json();
      }

      return Promise.reject({
        status: resp.status,
        errors: resp.error,
      });
    })
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      console.log(error);
    });
};
